/* @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@300;400;500;600;700;800&display=swap');
body {
  margin: 0;
  /* font-family: "Roboto", sans-serif; */

  font-family: 'Catamaran', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color : #233d63;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

.blogParagraph p {
  margin-bottom: 15px;
}

.blogParagraph blockquote {
  display: block;
  box-shadow: rgba(40, 61, 88, 0.07) 0px 0px 15px 0px;
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  font-style: italic;
  color: rgb(110, 110, 110);
  background: rgb(255, 255, 255);
  padding: 32px 60px 16px 40px;
  margin: 35px 0px 50px;
  border-left: 4px solid rgb(36, 93, 157);
}

.blogParagraph blockquote:before {
  content: open-quote;
  display: inline-block;
  position: absolute;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  line-height: 70px;
  top: auto;
  bottom: -30px;
  right: 35px;
  width: 70px;
  height: 70px;
  color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 50px;
  background: rgb(36, 93, 157);
}

.stickyNav {
  position: fixed;
  z-index: 12;
  left: 0px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  top: -40px;
  transform: translateY(40px);
  transition: transform 0.3s;
}

.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-toggler {
  border: none;
}

.swiper {
  width: 100%;
  height: 100%;
}
.serviceSlider .swiper {
  height: 100%;
}
.testoSwiper .swiper {
  height: 100%;
}

.swiper-slide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide {
  min-width: 290px;
}

#blogsSlider .swiper {
  height: 100%;
}

#portfolio .swiper-slide-active .sliderShadow {
  background-image: linear-gradient(
    to right,
    rgb(0, 0, 0, 0.04),
    rgb(0, 0, 0, 0.04)
  );
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  width: 20px;
  border-radius: 6px;
  background-color: #f9a51b;
}

.cardsServices {
  border-radius: 24px;
  /* box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25); */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  background-color: #fff;
  margin: 0;
  position: relative;
  z-index: 0;
  border: none;
  transition: transform 0.3s cubic-bezier(0.34, 2, 0.6, 1), box-shadow 0.2s ease;
  transform: translateY(0px);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 12px;
  min-height: 470px;
}
.cardsServices-new {
  border-radius: 24px;
  /* box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25); */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  background-color: #fff;
  margin: 0;
  position: relative;
  z-index: 0;
  border: none;
  transition: transform 0.3s cubic-bezier(0.34, 2, 0.6, 1), box-shadow 0.2s ease;
  transform: translateY(0px);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  min-height: 470px;
}
.cardsServices-new  h4{
  box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.19);
}

.cardsServices:hover {
  transform: translateY(-12px);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 15px 80px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 30px -30px;


    
}

.m-t10 {
  margin-top: 10px;
}

.m-b15 {
  margin-bottom: 15px;
}
.m-b30 {
  margin-bottom: 30px;
}

.text-primary {
  color: #d88c1f !important;
}
.bgl-primary {
  background-color: rgb(65, 189, 255, 0.1);


}
.bgl-secondary-lt{
  background-color: #62c6fc;
}
.bgl-secondary{
  background-color: #31B8FF;
}

h2.title {
  font-size: 45px;
  font-weight: 700;
}
.sub-title {
  padding: 10px 30px;
  display: inline-block;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  color: #127dc2;
}
@media only screen and (max-width: 1199px) {
  h2.title {
    font-size: 36px;
  }
}
@media only screen and (max-width: 575px) {
  .sub-title {
    font-size: 16px;
  }
  h2.title {
    font-size: 32px;
  }
}
.swiper-pagination-bullet {
  width: 8px;
  border-radius: 8px;
  background-color: #004163;
  opacity: 0.6;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  width: 20px;
  border-radius: 6px;
  background-color: #004163;
}

.icon-wraper-1 > div:nth-child(4),
.icon-wraper-1 > div:nth-child(7),
.icon-wraper-1 > div:nth-child(10),
.icon-wraper-1 > div:nth-child(13) {
  /* margin-top: -100px; */
}
@media only screen and (max-width: 1199px) {
  .icon-wraper-1 > div:nth-child(4),
  .icon-wraper-1 > div:nth-child(7),
  .icon-wraper-1 > div:nth-child(10),
  .icon-wraper-1 > div:nth-child(13) {
    /* margin-top: -70px; */
  }
}
@media only screen and (max-width: 991px) {
  .icon-wraper-1 > div:nth-child(4),
  .icon-wraper-1 > div:nth-child(7),
  .icon-wraper-1 > div:nth-child(10),
  .icon-wraper-1 > div:nth-child(13) {
    /* margin-top: 0; */
  }
}
.icon-bx-wraper {
  position: relative;
}

.icon-bx-wraper.style-3 {
  /* box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1); */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  transition: all 0.5s;
  overflow: hidden;
  z-index: 4;
}
.icon-bx-wraper.style-3 > [class*="icon-bx-"] {
  position: relative;
  width: 70px;
  height: 70px;
  line-height: 70px;
  margin: 15px 15px 30px;
  transition: all 0.5s;
}
.icon-bx-wraper.style-3 > [class*="icon-bx-"] .icon-cell i {
  font-size: 60px;
}
.icon-bx-wraper.style-3 > [class*="icon-bx-"]:before {
  content: "";
  position: absolute;
  height: 100px;
  width: 105px;
  left: -13px;
  top: -15px;
  background-image: url(../src//Assets//pattern.svg);
  background-repeat: no-repeat;
  background-size: cover;
}
.icon-bx-wraper.style-3 .wraper-effect {
  border-radius: 50%;
  position: absolute;
  transition: all 0.5s;
  height: 350px;
  width: 350px;
  background-color: rgba(255, 255, 255, 0.2);
  right: -100%;
  bottom: -100%;
  z-index: -1;
}
.icon-bx-wraper.style-3 .wraper-effect:before,
.icon-bx-wraper.style-3 .wraper-effect:after {
  content: "";
  border-radius: 50%;
  position: absolute;
  transition: all 0.5s;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.icon-bx-wraper.style-3 .wraper-effect:before {
  background-color: rgba(255, 255, 255, 0.2);
  height: 250px;
  width: 250px;
}
.icon-bx-wraper.style-3 .wraper-effect:after {
  background-color: rgb(65, 189, 255, 0.3);
  height: 150px;
  width: 150px;
}
.icon-bx-wraper.style-3:hover,
.icon-bx-wraper.style-3.active {
  background-image: linear-gradient(
    /* to right,
    #16a4ef 0,
    #d88c1f 101%,
    #16a4ef 100% */

    58deg, #01519B 13.77%, #046DA8 64.8%

  );
  background-size: 200%;
}
.icon-bx-wraper.style-3:hover .dlab-title,
.icon-bx-wraper.style-3:hover p,
.icon-bx-wraper.style-3:hover .icon-cell,
.icon-bx-wraper.style-3.active .dlab-title,
.icon-bx-wraper.style-3.active p,
.icon-bx-wraper.style-3.active .icon-cell {
  color: #fff;
}
.icon-bx-wraper.style-3:hover > [class*="icon-bx-"],
.icon-bx-wraper.style-3.active > [class*="icon-bx-"] {
  background-color: rgba(255, 255, 255, 0.2);
}
.icon-bx-wraper.style-3:hover .wraper-effect,
.icon-bx-wraper.style-3.active .wraper-effect {
  right: -150px;
  bottom: -150px;
}
.icon-bx-wraper.style-3:hover > [class*="icon-bx-"]:before,
.icon-bx-wraper.style-3.active > [class*="icon-bx-"]:before {
  background-image: url(../src//Assets//pattern.svg);
}
.icon-bx-wraper.style-3.left > [class*="icon-bx-"]:after {
  content: "";
  width: 70px;
  height: 50vh;
  background-color: rgba(245, 95, 141, 0.1);
  position: absolute;
  bottom: 0;
  left: 0;
  top: -4px;
  border-radius: 50px;
}
.icon-bx-wraper.style-3.left .icon-content {
  padding-left: 25px;
}
.icon-bx-wraper.style-3.left:hover > [class*="icon-bx-"],
.icon-bx-wraper.style-3.left.active > [class*="icon-bx-"] {
  background-color: transparent;
}
.icon-bx-wraper.style-3.left:hover > [class*="icon-bx-"]:after,
.icon-bx-wraper.style-3.left.active > [class*="icon-bx-"]:after {
  background-color: rgba(255, 255, 255, 0.2);
}
@media only screen and (max-width: 767px) {
  .icon-bx-wraper.style-3.left {
    margin-right: 0;
    margin-left: 0;
  }
}
@media only screen and (max-width: 575px) {
  .icon-bx-wraper.style-3.left {
    padding: 30px 20px 30px 15px;
  }
  .icon-bx-wraper.style-3.left > [class*="icon-bx-"]:after {
    height: 70px;
    top: 0;
  }
  .icon-bx-wraper.style-3.left .icon-content {
    padding-left: 15px;
  }
}

.icon-bx-sm {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 80px;
  height: 80px;
  line-height: 80px;
}
.icon-bx-sm.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.icon-bx-sm i {
  font-size: 40px;
  vertical-align: middle;
}
.icon-bx-sm img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 30px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 500px;
  background: white;
  color: currentColor;
  text-decoration: none;
  overflow: hidden;
  transition-property: color;
  transition-delay: 0.015s;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
}
.blogDetails .card {
  width: 100%;
}
.card:hover {
  color: white;
  transition-delay: 0;
}
.card,
.card__image,
.card__image:after,
.card__author,
.card__body,
.card__foot,
.card__border {
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.51, 0.92, 0.24, 1);
}

.card__head {
  position: relative;
  padding-top: 70%;
}
.card__author {
  position: absolute;
  padding: 2em;
  left: 0;
  bottom: 0;
  color: white;
  transition-delay: 0.15s;
}

.card__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  transform-origin: top center;
  transition-delay: 0.15s;
}
.card__image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  background: linear-gradient(
    30deg,
    rgba(26, 42, 99, 0.85),
    rgba(26, 42, 99, 0.5)
  );
  transition-property: opacity;
  transition-delay: 0.15s;
}

.card:hover .card__image:after {
  opacity: 0.9;
  transition-delay: 0;
}
.card__body {
  position: relative;
  padding: 2em;
  transition-delay: 0.01s;
}

.card__headline {
  font-weight: 400;
  margin: 0 0 0.8em;
}
.card__text {
  line-height: 1.5;
  margin: 0;
  opacity: 0.8;
}
.card__foot {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 2em 2em;
  opacity: 0;
  transition-property: opacity;
}
.card:hover .card__foot {
  opacity: 1;
  transition-delay: 0.15s;
}
.card__link {
  color: currentColor;
  text-decoration: none;
  border-bottom: 2px solid #faa831;
}
.card__border {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 6px;
  background: #faa831;
  transform: scaleY(0);
  transition-property: transform;
}
.card:hover .card__border {
  transform: none;
  transition-delay: 0.15s;
}
.author {
  display: flex;
  align-items: center;
}
.author__image {
  flex-shrink: 0;
  margin-right: 1em;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  overflow: hidden;
  background: rgb(0, 130, 198, 0.7);
  object-fit: contain;
  object-position: center;
}
.author__content {
  display: grid;
  grid-gap: 0.4em;
  font-size: 0.9em;
}
.author__header {
  margin: 0;
  font-weight: 600;
}
.author__subheader {
  margin: 0;
}

.video_button div.play_btn {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #0082c6;
  color: #fff;
  font-size: 20px;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
}

.video_button div.play_btn:after {
  position: absolute;
  content: "";
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
  border: 1.5px solid #fff;
  background: #0082c6;
  top: 50%;
  left: 50%;
  z-index: -1;
  overflow: hidden;
  transform: translate(-50%, -50%);
  animation: pulse-border 1500ms ease-out infinite;
}

.video_button div.play_btn:before {
  position: absolute;
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  border: 1.5px solid #fff;
  background: #0082c6;
  top: 50%;
  left: 50%;
  z-index: -1;
  overflow: hidden;
  transform: translate(-50%, -50%);
  animation: pulse-border 1500ms ease-out infinite;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) scale(1);
    opacity: 0;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) scale(1.3);
    opacity: 1;
  }
}

.video-responsive {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

/* //loading */
.loading {
  display: inline-block;
  position: relative;
  width: 27px;
  height: 27px;
}
.loading div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 27px;
  height: 27px;
  margin: 0px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.loading div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.swiperButton .swiper-button-next,
.swiperButton .swiper-button-prev {
  color: #faa831;
}

.animatedUp {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
.animatedDown {
  animation: down-up 2s ease-in-out infinite alternate-reverse both;
}
.animatedUPs {
  animation: up 3s ease-in-out infinite alternate-reverse both;
}

@keyframes up {
  0% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(-4px);
  }
}

@keyframes down-up {
  0% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-2px);
  }
}

@media only screen and (max-width: 548px) {
  .swiper-button-next {
    right: 0px;
  }
  .swiper-button-prev {
    left: 0px;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 26px;
  }
}
